




















































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {WithEvents} from 'vue-typed-emit';
import {calendarStoreGetter, calendarStoreMutations} from '@/store/calendar.store';
import {CalendarContextMenuEvents} from '@/interfaces/CalendarContextMenuEvents.interface';
import Slot from '@/models/Slot.model';
import ShipArrival from '@/models/ShipArrival.model';
import {shipArrivalStoreActions, shipArrivalStoreGetter} from '@/store/shipArrival.store';
import {ShiftType} from '@/enums/ShiftType.enum';
import {ShiftTypeSpecial} from '@/enums/ShiftTypeSpecial.enum';
import {DateTime} from 'luxon';
import SlotBookingRequest from '@/interfaces/SlotBookingRequest.interface';
import {slotStoreActions, slotStoreGetter} from '@/store/slot.store';
import {BookingState} from '@/enums/BookingState.enum';
import Voyage from '@/models/Voyage.model';
import Vessel from '@/models/Vessel.model';
import {UserRole} from '@/models/User.model';
import {getShiftStartTimes, getSpecialShiftStartTimes} from '@/misc/SlotTimeHelper';
import {SlotType} from '@/enums/SlotType.enum';

const CalendarStore = namespace('calendar');
const SlotStore = namespace('slot');
const ShipArrivalStore = namespace('shipArrival');

/**
 * Context menu for ConvoySchedules
 */
@Component({
  components: {
    CalendarBaseContextComponent: () => import(
        /* webpackChunkName: "CalendarBaseContextComponent" */
        '@/components/calendar/context/CalendarBaseContextMenu.component.vue')
  }
})
export default class CalendarSlotContextMenuComponent extends (Vue as WithEvents<CalendarContextMenuEvents<any>>) {

  /**
   * The min date when an interaction for non admin user is possible
   */
  @Prop()
  public minDate!: DateTime;

  /**
   * The max date when an interaction for non admin user is possible
   */
  @Prop()
  public maxDate!: DateTime;

  @CalendarStore.Getter(calendarStoreGetter.SELECTION_INFORMATION)
  private _selectionInformation!: undefined | Partial<SlotBookingRequest>;

  public get selectionInformation(): undefined | Partial<SlotBookingRequest> {
    return this._selectionInformation;
  }

  @CalendarStore.Getter(calendarStoreGetter.SLOT_SELECTION_INFORMATION)
  private _slotSelectionInformation!: undefined | Partial<Slot>;

  public get slotSelectionInformation(): undefined | Partial<Slot> {
    return this._slotSelectionInformation;
  }

  @CalendarStore.Mutation(calendarStoreMutations.SET_SHOW_CREATE_CONTEXT_MENU)
  private setShowCreateContextMenu!: (payload: boolean) => void;

  @CalendarStore.Getter(calendarStoreGetter.SHOW_CREATE_CONTEXT_MENU)
  private showCreateContextMenu!: boolean;

  @CalendarStore.Getter(calendarStoreGetter.SHOW_CONTEXT_MENU)
  private _showContextMenu!: boolean;

  public get showShowContextMenu(): boolean {
    return this._showContextMenu;
  }

  @SlotStore.Action(slotStoreActions.CREATE_SLOT)
  private createSlotAction!: (slot: SlotBookingRequest) => Promise<Slot>;

  @ShipArrivalStore.Action(shipArrivalStoreActions.UPDATE_CALENDAR_SHIP_ARRIVAL)
  private updateCalendarShipArrivalAction!: () => Promise<ShipArrival>;

  @ShipArrivalStore.Getter(shipArrivalStoreGetter.CALENDAR_SHIP_ARRIVAL)
  private _calendarShipArrival!: ShipArrival | null;

  public get calendarShipArrival(): ShipArrival | null {
    return this._calendarShipArrival;
  }

  @SlotStore.Getter(slotStoreGetter.SLOTTING_ERROR)
  private _slottingError!: boolean;

  public get slottingError(): boolean {
    return this._slottingError;
  }

  get subtitle(): null | string {
    return this.calendarShipArrival ? ((this.calendarShipArrival.voyage as Voyage).vessel as Vessel).name! : null;
  }

  /**
   *  Flag which determines if current date is out of time range
   */
  public isOutOfTimeRange: boolean = true;

  /**
   *  Flag which determines if current date is out of the eta / ets range
   */
  public isOutOfEtaEtsRange: boolean = true;

  /**
   *  Flag which determines if a special shift is required for making a slot booking
   */
  public specialShiftRequired: boolean = true;

  /**
   *  Flag which determines if the user selected to many slots (CAR or H&H)
   */
  public reachedNecessarySlots: boolean = true;

  /**
   * Assemble error for creation
   */
  get createItemError(): { tooltip: string, icon: string } | null {
    const currBookingState = this.calendarShipArrival?.booking?.status;
    let error = null;

    if (currBookingState && (currBookingState === BookingState.ACCEPTED
        || currBookingState === BookingState.CANCELED || currBookingState === BookingState.DECLINED)
        && !this.$hasRole([UserRole.KEY_USER, UserRole.IT_AUTOMOBILE, UserRole.PLANER])) {
      error = {
        tooltip: this.$t('CALENDAR.ALREADY_BOOKED_ERROR').toString(),
        icon: 'fas fa-ban'
      }
    } else if (!this.selectionInformation) {
      error = {
        tooltip: this.$t('CALENDAR.SELECTION_ERROR').toString(),
        icon: 'fas fa-question-circle'
      }
    } else if (this.isOutOfTimeRange && !this.$hasRole([UserRole.KEY_USER, UserRole.IT_AUTOMOBILE, UserRole.PLANER])) {
      error = {
        tooltip: this.$t('CALENDAR.OUT_OF_TIME_RANGE_ERROR').toString(),
        icon: 'fas fa-lock'
      }
    } else if (this.isOutOfEtaEtsRange && !this.$hasRole([UserRole.KEY_USER, UserRole.IT_AUTOMOBILE, UserRole.PLANER])) {
      error = {
        tooltip: this.$t('CALENDAR.ETA_ETS_RANGE_ERROR').toString(),
        icon: 'fas fa-lock'
      }
    } else if (this.reachedNecessarySlots && !this.$hasRole([UserRole.KEY_USER, UserRole.IT_AUTOMOBILE, UserRole.PLANER])) {
      error = {
        tooltip: this.$t('CALENDAR.TOO_MANY_SLOTS_ERROR').toString(),
        icon: 'fas fa-lock'
      }
    } else if (this.slottingError && !this.$hasRole([UserRole.KEY_USER, UserRole.IT_AUTOMOBILE, UserRole.PLANER])) {
      error = {
        tooltip: this.$t('CALENDAR.SLOTTING_ERROR').toString(),
        icon: 'fas fa-exclamation-triangle'
      }
    }
    return error;
  }

  /**
   * Assemble error for details
   */
  get generalItemError(): { tooltip: string, icon: string } | null {
    let error = null;
    if (!this.calendarShipArrival) {
      error = {
        tooltip: this.$t('CALENDAR.SHIP_ARRIVAL_ERROR').toString(),
        icon: 'fas fa-ship'
      }
    }
    return error;
  }

  private availableSpecialShifts: ShiftTypeSpecial[] = [];
  public selectedSpecialShift: ShiftTypeSpecial | null = null;

  public isLoading: boolean = false;
  public isSending: boolean = false;

  public UserRole = UserRole;

  @Watch('showShowContextMenu', {immediate: true})
  private async onIsSelectionSlotChange() {
    if (this.showShowContextMenu) {
      this.isOutOfTimeRange = false;
      // Check if selection is in interaction frame
      if (this.selectionInformation) {
        const selectionDate = DateTime.fromISO(this.selectionInformation.date!);
        this.isOutOfTimeRange = selectionDate < this.minDate || selectionDate > this.maxDate;
        if (this.calendarShipArrival) {
          this.checkEtaEtsDate();
          this.checkNecessarySlots();
        }
      }
      const isBookingRequest = !!(this.selectionInformation && !(this.selectionInformation instanceof Slot));
      if (isBookingRequest && this.calendarShipArrival) {
        let etaDate: DateTime;
        if (typeof (this.calendarShipArrival!.voyage as Voyage).ets === 'string') { // Sometimes there seem to be date objects?
          etaDate = DateTime.fromISO((this.calendarShipArrival!.voyage as Voyage).eta as string);
        } else {
          etaDate = DateTime.fromISO(((this.calendarShipArrival!.voyage as Voyage).eta as Date).toISOString())
        }
        this.availableSpecialShifts = this.determineAvailableSpecialShifts(etaDate);
      }
    }
  }

  public checkEtaEtsDate() {
    let selectionDate = DateTime.fromISO(this.selectionInformation!.date!);
    const shifts = [ShiftType.FIRST, ShiftType.SECOND, ShiftType.THIRD];
    const shiftObjects = getShiftStartTimes(selectionDate.weekday);
    const shiftIndex = shifts.indexOf(this.selectionInformation!.shift!);
    const shiftStartTime = shiftObjects[shiftIndex];
    selectionDate = selectionDate.startOf('day')
        .plus({hours: shiftStartTime.startHour, minutes: shiftStartTime.startMinute});
    let etaDate: DateTime, etsDate: DateTime;
    if (typeof (this.calendarShipArrival!.voyage as Voyage).eta === 'string') { // Sometimes there seem to be date objects?
      etaDate = DateTime.fromISO((this.calendarShipArrival!.voyage as Voyage).eta as string);
    } else {
      etaDate = DateTime.fromISO(((this.calendarShipArrival!.voyage as Voyage).eta as Date).toISOString())
    }

    if (typeof (this.calendarShipArrival!.voyage as Voyage).ets === 'string') { // Sometimes there seem to be date objects?
      etsDate = DateTime.fromISO((this.calendarShipArrival!.voyage as Voyage).ets as string);
    } else {
      etsDate = DateTime.fromISO(((this.calendarShipArrival!.voyage as Voyage).ets as Date).toISOString())
    }
    this.isOutOfEtaEtsRange = selectionDate < etaDate || selectionDate > etsDate;

    if (this.isOutOfEtaEtsRange) {
      const availableSpecialShifts = this.determineAvailableSpecialShifts(etaDate);
      this.isOutOfEtaEtsRange = availableSpecialShifts.length === 0;
      this.specialShiftRequired = availableSpecialShifts.length > 0;
    } else {
      this.specialShiftRequired = false;
    }
  }

  public checkNecessarySlots() {
    if (this.selectionInformation?.slotType === SlotType.CAR &&
        this.calendarShipArrival!.getRequiredCarSlots() < this.selectionInformation!.amount!) {
      this.reachedNecessarySlots = true;
    } else if (this.selectionInformation?.slotType === SlotType.HEAVY &&
        this.calendarShipArrival!.getRequiredHeavySlots() < this.selectionInformation!.amount!) {
      this.reachedNecessarySlots = true;
    } else {
      this.reachedNecessarySlots = false;
    }
  }

  /**
   * Determines which special shifts are available
   */
  public determineAvailableSpecialShifts(eta: DateTime): ShiftTypeSpecial[] {
    let availableShifts: ShiftTypeSpecial[] = [];
    if (this.selectionInformation) {
      const selectionDate = DateTime.fromISO(this.selectionInformation!.date!);
      // Check if weekday is not saturday or sunday
      if (![6, 7].includes(selectionDate.weekday)) {
        if (this.selectionInformation!.shift === ShiftType.FIRST) {
          availableShifts = [ShiftTypeSpecial.TG, ShiftTypeSpecial.MS1];
        }
        if (this.selectionInformation!.shift === ShiftType.SECOND) {
          availableShifts = [ShiftTypeSpecial.MS2];
        }
      }

      const specialShiftObjects = getSpecialShiftStartTimes();
      availableShifts = availableShifts.filter(shift => {
        const specialShiftStartTime = specialShiftObjects.find(time => time.shift === shift);
        const specialShiftStartTimeDate = selectionDate.startOf('day')
            .plus({hours: specialShiftStartTime!.startHour, minutes: specialShiftStartTime!.startMinute});
        return specialShiftStartTimeDate > eta;
      });
    }
    return availableShifts;
  }

  public async onCreationFormSubmit() {
    if (!this.isLoading && !this.isSending && this.calendarShipArrival) {

      try {
        this.isSending = true;
        const payload = {
          shipArrival: this.calendarShipArrival!.id,
          ...this.selectionInformation
        } as SlotBookingRequest;
        await this.createSlotAction(payload);
        await this.updateCalendarShipArrivalAction();
        this.$notifySuccessSimplified('CALENDAR.CONTEXT_MENU.NOTIFICATIONS.CREATE_SLOT_SUCCESS');
        this.onCreationFormCancel();
      } catch (e) {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      } finally {
        this.isSending = false;
      }
    }
  }

  public onCreationFormCancel() {
    this.clearState();
    this.setShowCreateContextMenu(false);
  }

  /**
   * Clear state
   * @private
   */
  private clearState() {
    this.availableSpecialShifts = [];
    this.selectedSpecialShift = null;
  }

  /**
   * Handle create intention
   */
  public onContextMenuCreateClick() {
    if (!this.createItemError) {
      this.setShowCreateContextMenu(true);
    }
  }

  /**
   * Handle move slot intention
   */
  public onContextMenuMoveClick() {
    this.$emit('move-slot');
  }

  /**
   * Handle details intention
   */
  public onContextMenuDetailsClick() {
    if (!this.generalItemError) {
      this.$emit('details-booking');
    }
  }
}
