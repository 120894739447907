var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CalendarBaseContextComponent',{on:{"details-booking":function($event){return _vm.$emit('details-booking')}},scopedSlots:_vm._u([{key:"base-context-menu",fn:function(){return [_c('v-list',{staticClass:"calendar-context-menu primary--text"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('h3',{staticClass:"mx-4 mb-n1"},[_vm._v(_vm._s(_vm.$t('CALENDAR.CONTEXT_MENU.ACTIONS_TITLE')))]),(_vm.subtitle)?_c('small',{staticClass:"mx-4 caption"},[_vm._v(_vm._s(_vm.$t('GENERAL.FOR'))+" "+_vm._s(_vm.subtitle))]):_vm._e()]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.generalItemError)?_c('v-icon',_vm._g({staticClass:"ml-auto mt-1 mr-4",attrs:{"small":"","color":"error"}},on),[_vm._v(" "+_vm._s(_vm.generalItemError.icon)+" ")]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.generalItemError && _vm.generalItemError.tooltip))])])],1),_c('v-divider',{staticClass:"mt-3 mb-1"}),_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.createItemError || !!_vm.generalItemError},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"calendar-context-menu-entry",class:{
                                          'not-allowed always-clickable': _vm.createItemError,
                                          'primary--text': !_vm.createItemError && !_vm.generalItemError
                                      },attrs:{"inactive":"","disabled":!!_vm.createItemError || !!_vm.generalItemError}},on),[_c('v-list-item-content',{on:{"click":function($event){$event.preventDefault();return _vm.onContextMenuCreateClick()}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('CALENDAR.CONTEXT_MENU.ACTIONS.CREATE'))+" ")])],1),(_vm.createItemError && !_vm.generalItemError)?_c('v-list-item-icon',{staticClass:"justify-center"},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(_vm._s(_vm.createItemError.icon))])],1):_vm._e()],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.createItemError && _vm.createItemError.tooltip))])]),_c('v-list-item',{staticClass:"calendar-context-menu-entry",class:{
                            'not-allowed always-clickable': _vm.generalItemError,
                            'primary--text': !_vm.generalItemError
                          },attrs:{"disabled":!!_vm.generalItemError,"inactive":""}},[_c('v-list-item-content',{on:{"click":function($event){return _vm.onContextMenuDetailsClick()}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('CALENDAR.CONTEXT_MENU.ACTIONS.DETAILS'))+" ")])],1)],1),(_vm.slotSelectionInformation && _vm.slotSelectionInformation.id && !_vm.$hasRole(_vm.UserRole.SHIP_OWNER))?_c('v-list-item',{staticClass:"calendar-context-menu-entry primary--text"},[_c('v-list-item-content',{on:{"click":function($event){$event.preventDefault();return _vm.onContextMenuMoveClick()}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('CALENDAR.CONTEXT_MENU.ACTIONS.MOVE'))+" ")])],1)],1):_vm._e()],1)]},proxy:true},{key:"creation-menu-content",fn:function(){return [_c('v-card',{staticClass:"create-content pa-2",attrs:{"width":"450"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var hover = ref.hover;
return [_c('v-btn',{staticClass:"close-icon-element",attrs:{"icon":""},on:{"click":function($event){return _vm.onCreationFormCancel()}}},[_c('v-icon',{attrs:{"color":hover ? 'close-icon-darken1' : 'close-icon'}},[_vm._v(" fas fa-times ")])],1)]}}])}),_c('v-card-title',{staticClass:"primary--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('CALENDAR.CONTEXT_MENU.SELECT_SHIP_ARRIVAL_TITLE'))+" ")]),(_vm.selectionInformation)?_c('v-card-text',{staticClass:"primary--text"},[_c('div',{staticClass:"d-flex align-center mb-3"},[_c('h3',[_vm._v(_vm._s(_vm.$t('CALENDAR.CONTEXT_MENU.SHIP_ARRIVAL_DATA.CONCLUSION')))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"small":"","color":"primary"}},on),[_vm._v("fas fa-question-circle ")])]}}],null,false,131516034)},[_c('span',[_vm._v(_vm._s(_vm.$t('CALENDAR.CONTEXT_MENU.TOOLTIPS.CONCLUSION')))])])],1),_c('div',{staticClass:"d-flex flex-column",class:{ 'mb-6' : _vm.availableSpecialShifts.length > 0 }},[_c('div',{staticClass:"d-flex justify-space-between mb-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('CALENDAR.CONTEXT_MENU.SHIP_ARRIVAL_DATA.DATE'))+": ")]),_c('span',[_vm._v(_vm._s(_vm.$isoStringDateHumanized(_vm.selectionInformation.date)))])]),_c('div',{staticClass:"d-flex justify-space-between mb-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('CALENDAR.CONTEXT_MENU.SHIP_ARRIVAL_DATA.SHIFT'))+": ")]),_c('span',[_vm._v(_vm._s(_vm.$t(("SHIFTS." + (_vm.selectionInformation.shift)))))])]),_c('div',{staticClass:"d-flex justify-space-between mb-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('CALENDAR.CONTEXT_MENU.SHIP_ARRIVAL_DATA.TYPE_AND_COUNT'))+": ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t(("GENERAL." + (_vm.selectionInformation.slotType))))+" / "+_vm._s(_vm.selectionInformation.amount)+" ")])])]),(_vm.availableSpecialShifts.length > 0)?_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"d-flex align-center mb-3"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('CALENDAR.CONTEXT_MENU.SHIP_ARRIVAL_DATA.SPECIAL_SHIFTS')))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"small":"","color":"primary"}},on),[_vm._v("fas fa-question-circle ")])]}}],null,false,131516034)},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm.$t('CALENDAR.CONTEXT_MENU.TOOLTIPS.SPECIAL_SHIFTS'))+":")]),_c('span',[_vm._v("TG: 08:00-16:00")]),_c('span',[_vm._v("MS1: 10:00-18:00")]),_c('span',[_vm._v("MS2: 18:00-02:00")])])])],1),_vm._l((_vm.availableSpecialShifts),function(specialShift){return _c('v-checkbox',{key:specialShift,staticClass:"mt-1",attrs:{"value":specialShift,"hide-details":"","color":"success","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.$t(("SPECIAL_SHIFT_TYPES." + specialShift))))])]},proxy:true}],null,true),model:{value:(_vm.selectionInformation.shiftComment),callback:function ($$v) {_vm.$set(_vm.selectionInformation, "shiftComment", $$v)},expression:"selectionInformation.shiftComment"}})}),(_vm.specialShiftRequired)?_c('div',{staticClass:"caption mt-3"},[_c('span',[_vm._v(_vm._s(_vm.$t('CALENDAR.CONTEXT_MENU.SPECIAL_SHIFT_REQUIRED')))])]):_vm._e()],2):_vm._e()]):_vm._e(),_c('v-card-actions',{staticClass:"mx-n2 mb-n2 surface fake-top-shadow"},[_c('div',{staticClass:"pa-4 d-flex align-start"},[_c('v-btn',{staticClass:"mr-3",attrs:{"depressed":"","disabled":_vm.specialShiftRequired && _vm.selectionInformation && !_vm.selectionInformation.shiftComment,"color":"primary","loading":_vm.isSending},on:{"click":function($event){$event.preventDefault();return _vm.onCreationFormSubmit()}}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.CONFIRM'))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.preventDefault();return _vm.onCreationFormCancel()}}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.CANCEL'))+" ")])],1)])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }