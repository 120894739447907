/**
 * Support class that provides function throtteling
 */
export default class Throttler {

    private throttleTimeout?: any;
    private THROTTLE_DELAY: number;

    constructor(defaultDelay = 300) {
        this.THROTTLE_DELAY = defaultDelay;
    }

    /**
     * Throttles the passed function
     */
    public throttle(fn: () => void) {
        if (this.throttleTimeout) {
            clearTimeout(this.throttleTimeout);
            this.throttleTimeout = undefined;
        }
        this.throttleTimeout = setTimeout(() => {
            fn();
        }, this.THROTTLE_DELAY);
    }
}
