


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CalendarItemInterface } from '@/interfaces/CalendarItem.interface';
import Slot from '@/models/Slot.model';
import { Booking } from '@/models/Booking.model';
import ShipOwner from '@/models/ShipOwner.model';
import { BookingState } from '@/enums/BookingState.enum';
import { shipArrivalStoreGetter } from '@/store/shipArrival.store';
import ShipArrival from '@/models/ShipArrival.model';
import { namespace } from 'vuex-class';
import { SlotType } from '@/enums/SlotType.enum';
import { UserRole } from '@/models/User.model';
import { SlotItemState } from '@/interfaces/SlotItemState.interface';
import { calendarStoreGetter } from '@/store/calendar.store';
import SlotBookingRequest from '@/interfaces/SlotBookingRequest.interface';

const ShipArrivalStore = namespace('shipArrival');
const CalendarStore = namespace('calendar');

@Component({
    components: {
        CalendarItemBaseComponent: () => import(
            /* webpackChunkName: "CalendarItemBaseComponent" */
            '@/components/calendar/items/CalendarItemBase.component.vue'),
        CarSlotAmountIndicatorComponent: () => import(
            /* webpackChunkName: "CarSlotAmountIndicatorComponent" */
            '@/components/calendar/misc/CarSlotAmountIndicator.component.vue'),
        HHSlotAmountIndicatorComponent: () => import(
            /* webpackChunkName: "HHSlotAmountIndicatorComponent" */
            '@/components/calendar/misc/HHSlotAmountIndicator.component.vue'),
        VoyageKeyDataComponent: () => import(
            /* webpackChunkName: "VoyageKeyDataComponent" */
            '@/components/calendar/misc/VoyageKeyData.component.vue')
    }
})
export default class CalendarItemSlotComponent extends Vue implements CalendarItemInterface {
    /**
     * Schedule data to work with
     */
    @Prop()
    public data!: Slot | null;

    /**
     * Coordinate of the item. Is passed through CalendarItemBaseComponent.
     */
    @Prop()
    public coordinate!: string;

    @ShipArrivalStore.Getter(shipArrivalStoreGetter.CALENDAR_SHIP_ARRIVAL_ID)
    private _calendarShipArrivalId!: string | null;

    public get calendarShipArrivalId(): string | null {
        return this._calendarShipArrivalId;
    }

    @CalendarStore.Getter(calendarStoreGetter.SLOT_SELECTION_INFORMATION)
    private _slotSelectionInformation!: Slot;

    public get slotSelectionInformation(): Slot | undefined {
        return this._slotSelectionInformation;
    }

    public BookingState = BookingState;
    public SlotType = SlotType;

    /**
     * Presents the slot state and therefore the appearance
     */
    public slotItemState: SlotItemState = {
        state: BookingState.NO_BOOKING,
        isOccupied: false,
        occupation: 1,
        color: null,
        opacity: 1,
        isStriped: false,
        isGrouped: false,
        isSelected: false
    };

    @Watch('data', { immediate: true })
    private onDataChange() {
        if (this.data) {
            const state = this.slotState();
            this.slotItemState = {
                state,
                isOccupied: state === BookingState.OCCUPIED,
                occupation: this.data?.amount ? this.data.amount : 1, // determines the amount if slots which will are occupied
                color: this.slotColor(state),
                opacity: this.slotOpacity(state),
                isStriped: this.isStriped(state),
                isGrouped: this.isGrouped(state),
                isSelected: this.isSelected()
            }
        }
    }

    @Watch('calendarShipArrivalId', { immediate: true })
    private onCalendarShipArrivalChange() {
        if (this.data) {
            this.slotItemState.isGrouped = this.isGrouped(this.slotItemState.state);
            this.slotItemState.isSelected = this.isSelected();
        }
    }

    @Watch('slotSelectionInformation', { immediate: true })
    private onSlotSelectionInformationChange() {
        if (this.data) {
            this.slotItemState.isSelected = this.isSelected();
        }
    }

    /**
     * Aupport function to determine state easily
     */
    private slotState(): BookingState {
        // Check if booking is available at all
        if (this.data?.booking) {
            if (this.data?.booking.status === BookingState.RESERVED) {
                return BookingState.RESERVED
            }
            if (this.data?.booking.status === BookingState.ACCEPTED) {
                return BookingState.ACCEPTED;
            }
        } else if (this.data) { // if data itself is available -> this is an ouccpied cell
            return BookingState.OCCUPIED;
        }
        // Is data null or not supported this is an empty cell
        return BookingState.NO_BOOKING; // empty
    }

    /**
     * Handle slot color
     */
    private slotColor(slotState: BookingState): string | null {

        switch (slotState) {
            case BookingState.ACCEPTED:
            case BookingState.RESERVED:
                // Special case, if user is a ship owner use the common ship owner color
                if (this.$hasRole(UserRole.SHIP_OWNER)) {
                    return this.$vuetify.theme.themes.light['calendar-item-ship-owner'] as string;
                } else {
                    return (((this.data?.booking as Booking).shipArrival as ShipArrival).shipOwner as ShipOwner).color ?? null;
                }
            case BookingState.OCCUPIED:
                return this.$vuetify.theme.themes.light['calendar-item-disabled'] as string;
            default:
                return null;
        }
    }

    /**
     * Handle slot opacity
     */
    private slotOpacity(slotState: BookingState): number {
        switch (slotState) {
            case BookingState.RESERVED:
                return .75;
            default:
                return 1;
        }
    }

    /**
     * Determine if slot should be striped or nor
     */
    private isStriped(slotState: BookingState): boolean {
        switch (slotState) {
            case BookingState.RESERVED:
                return true;
            default:
                return false;
        }
    }

    /**
     * Determine if slot is connected to global selected calendar ship arrival
     */
    private isGrouped(slotState: BookingState): boolean {
        switch (slotState) {
            case BookingState.RESERVED:
            case BookingState.ACCEPTED:
                return !!(this.calendarShipArrivalId && ((this.data?.booking as Booking).shipArrival as ShipArrival).id === this.calendarShipArrivalId);
            default:
                return false;
        }
    }

    /**
     * Check if the slot is selected (for moving)
     */
    private isSelected(): boolean {
        return this.data !== null && this.slotSelectionInformation !== undefined && this.data!.id === this._slotSelectionInformation.id;
    }
}
