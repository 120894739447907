



















































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {DateTime} from 'luxon';
import {CalendarItemInterface} from '@/interfaces/CalendarItem.interface';
import SlotGroup from '@/models/SlotGroup.model';
import CalendarItemSlotComponent from '@/components/calendar/items/CalendarItemSlot.component.vue';
import {ShiftType} from '@/enums/ShiftType.enum';
import {namespace} from 'vuex-class';
import {slotStoreActions, slotStoreGetter} from '@/store/slot.store';
import Throttler from '@/misc/Throttler';
import {changeWeekNumber} from '@/misc/helperMethods';
import CalendarSlotContextMenuComponent from '@/components/calendar/context/CalendarSlotContextMenu.component.vue';
import {CalendarContextMenuEvents} from '@/interfaces/CalendarContextMenuEvents.interface';
import CalendarComponent from '@/components/calendar/Calendar.component.vue';
import {ShiftConfig} from '@/interfaces/ShiftConfig.interface';
import {shipArrivalStoreActions, shipArrivalStoreGetter, shipArrivalStoreMutations} from '@/store/shipArrival.store';
import ShipArrival from '@/models/ShipArrival.model';
import {UserRole} from '@/models/User.model';
import {calendarStoreGetter, calendarStoreMutations} from '@/store/calendar.store';
import Slot from '@/models/Slot.model';
import {settingsStoreGetter} from "@/store/settings.store";
import {SlotSettings} from "@/interfaces/SlotSettings.interface";

const SlotStore = namespace('slot');
const SettingsStore = namespace('settings');
const CalendarStore = namespace('calendar');
const ShipArrivalStore = namespace('shipArrival');

@Component({
  components: {
    CalendarComponent: () => import(
      /* webpackChunkName: "CalendarComponent" */
      '@/components/calendar/Calendar.component.vue'),
    ShipArrivalPickerComponent: () => import(
      /* webpackChunkName: "ShipArrivalPickerComponent" */
      '@/components/calendar/misc/ShipArrivalPicker.component.vue'),
    BookingOverviewComponent: () => import(
      /* webpackChunkName: "BookingOverviewComponent" */
      '@/components/calendar/misc/BookingOverview.component.vue'),
    MoveSlotComponent: () => import(
      /* webpackChunkName: "MoveSlotComponent" */
      '@/components/calendar/misc/MoveSlot.component.vue'),
    ShipArrivalDetailsComponent: () => import(
      /* webpackChunkName: "ShipArrivalDetailsComponent" */
      '@/components/shipArrival/ShipArrivalDetails.component.vue'),
  }
})
export default class CalendarView extends Vue {

  @SettingsStore.Getter(settingsStoreGetter.SETTINGS)
  public settings!: SlotSettings;

  private currentDateTime: DateTime = DateTime.utc();
  /**
   * Minimal date for booking (normally 7 days in future from today)
   */
  public minDate: DateTime | null = null;

  /**
   * Maximal date for booking (normally 120 days in future from today)
   */
  public maxDate = this.currentDateTime
    .plus({days: Number(process.env.VUE_APP_MAX_BOOKING_FROM_TODAY!)})
    .startOf('day');

  private previousYearThrottler: Throttler = new Throttler(100);
  private nextYearThrottler: Throttler = new Throttler(100);
  public isLoading: boolean = true;
  public showWeekPickerDialog: boolean = false;
  public targetDate: string = this.currentDateTime.toISODate();
  public showDetailsDialog: boolean = false;

  public showMoveSlotDialog: boolean = false;

  get currentWeekNumber(): number {
    return this.currentDateTime.weekNumber;
  }

  get currentYear(): number {
    return this.currentDateTime.year;
  }

  // I do not know why.... but the CalendarContextMenu & CalendarContextMenuEvents<Project> declarations are not working here...
  // therefore we need to use the any...
  public contextMenuComponent: CalendarContextMenuEvents<any> = CalendarSlotContextMenuComponent as any;

  public calendarItemComponent: CalendarItemInterface = CalendarItemSlotComponent as any;

  @SlotStore.Action(slotStoreActions.GET_SHIFT_CONFIG)
  private getShiftConfigAction!: () => Promise<ShiftConfig>

  @SlotStore.Getter(slotStoreGetter.SHIFT_CONFIG)
  private _shiftConfig!: ShiftConfig;

  public get shiftConfig(): ShiftConfig {
    return this._shiftConfig;
  }

  @SlotStore.Action(slotStoreActions.GET_SLOT_GROUPS)
  private getSlotGroupsAction!: (payload: { shift: ShiftType, date: string }[]) => Promise<SlotGroup[]>

  @SlotStore.Getter(slotStoreGetter.SLOT_GROUPS)
  private _slotGroups!: SlotGroup[];

  public get slotGroups(): SlotGroup[] {
    return this._slotGroups;
  }

  @ShipArrivalStore.Mutation(shipArrivalStoreMutations.SET_CALENDAR_ARRIVAL)
  private setCalendarShipArrival!: (value: ShipArrival | undefined) => void;

  @ShipArrivalStore.Action(shipArrivalStoreActions.GET)
  private loadShipArrival!: (payload: { id: string }) => Promise<ShipArrival>;

  @ShipArrivalStore.Getter(shipArrivalStoreGetter.CALENDAR_SHIP_ARRIVAL)
  private _calendarShipArrival!: undefined | ShipArrival;

  public get calendarShipArrival(): undefined | ShipArrival {
    return this._calendarShipArrival;
  }

  @CalendarStore.Mutation(calendarStoreMutations.SET_SELECTED_WEEK)
  private setSelectedWeek!: (value: string | undefined) => void;

  @CalendarStore.Getter(calendarStoreGetter.SELECTED_WEEK)
  private selectedWeek!: undefined | string;

  @CalendarStore.Getter(calendarStoreGetter.SLOT_SELECTION_INFORMATION)
  private _slotSelectionInformation!: Slot | undefined;

  public get slotSelectionInformation(): undefined | Slot {
    return this._slotSelectionInformation;
  }

  public get calendarComponent(): Vue & CalendarComponent {
    return this.$refs['calendar-component'] as CalendarComponent;
  }

  @Watch('settings.lockedAmount', {immediate: true})
  public lockedAmountChanged() {
    this.minDate = this.currentDateTime
      .plus({days: this.settings.lockedAmount})
      .startOf('day');
  }

  public async mounted() {
    this.minDate = this.currentDateTime
      .plus({days: this.settings.lockedAmount})
      .startOf('day');
    if (this.$hasRole([UserRole.IT_AUTOMOBILE, UserRole.KEY_USER])) {
      this.maxDate = this.currentDateTime
        .plus({days: Number(process.env.VUE_APP_ADMIN_MAX_BOOKING_FROM_TODAY!)})
        .startOf('day');
    }

    // Check for KW query params
    if ('kw' in this.$route.query) {
      const splitted = (this.$route.query['kw'] as string).split('-');
      this.currentDateTime = DateTime.fromObject({
        weekYear: Number(splitted[0]),
        weekNumber: Number(splitted[1]),
        zone: 'utc'
      });
      this.setSelectedWeek(this.$route.query['kw'] as string);
    } else if (this.selectedWeek) {
      const splitted = (this.selectedWeek).split('-');
      this.currentDateTime = DateTime.fromObject({
        weekYear: Number(splitted[0]),
        weekNumber: Number(splitted[1]),
        zone: 'utc'
      });
    } else {
      // Add query parameter if no available
      this.updateQueryParameter();
    }
    try {
      await Promise.all([
        this.getShiftConfigAction(),
        this.loadSlotGroups()
      ]);
    } catch (e) {
      console.log('e', e);
      this.$notifyErrorSimplified('CALENDAR.NOTIFICATIONS.INITIAL_LOADING_ERROR');
    }
    // Scroll to today if possible
    const today = DateTime.utc().startOf('day');
    if (this.currentDateTime.weekNumber === today.weekNumber && !this.$route.query['weekday']) {
      // Hacky time out depending implementation - TODO: Improve!
      setTimeout(() => {
        this.calendarComponent.scrollToDate(today.toISO());
      }, 800);
    }
  }

  public beforeDestroy() {
    this.setCalendarShipArrival(undefined);
  }

  public async reloadSelected() {
    if (this._calendarShipArrival) {
      const shipArrival = await this.loadShipArrival({id: this._calendarShipArrival.id!});
      this.setCalendarShipArrival(shipArrival);
    }
  }

  /**
   * Reload all
   */
  public async reloadCalendar(slotDate: string) {
    const targetDateTime = DateTime.fromISO(slotDate);
    const query = {
      kw: `${this.currentDateTime.year}-${this.currentDateTime.weekNumber}`,
      weekday: targetDateTime.weekday.toString()
    };

    // Bugfix for redundant navigation
    if (!this.$route.query['kw'] || this.$route.query['kw'] !== query.kw ||
      !this.$route.query['weekday'] || this.$route.query['weekday'] !== query.weekday) {
      await this.$router.replace({
        name: this.$route.name!,
        query
      });
    }

    await this.$router.go(0);
  }

  /**
   * Event handler for handle next year change
   */
  public onNextWeekNumber() {
    this.nextYearThrottler.throttle(() => {
      this.onWeekNumberChanged(1);
    });
  }

  /**
   * Event handler for handle previous year change
   */
  public onPreviousWeekNumber() {
    this.previousYearThrottler.throttle(() => {
      this.onWeekNumberChanged(-1);
    });
  }

  /**
   * Event handler to handle week number change.
   */
  public async onWeekNumberChanged(change: 1 | -1) {
    this.currentDateTime = changeWeekNumber(this.currentDateTime, change);
    this.updateQueryParameter();
    await this.loadSlotGroups();
  }

  /**
   * Handle target date confirmation
   */
  public async onTargetDateConfirm() {
    const targetDateTime = DateTime.fromFormat(this.targetDate, 'yyyy-MM-dd', {zone: 'utc'});
    this.currentDateTime = targetDateTime.startOf('week');
    this.updateQueryParameter();
    this.showWeekPickerDialog = false;
    await this.loadSlotGroups();
    // Scroll to target day
    this.calendarComponent.scrollToDate(targetDateTime.toISO());
  }

  /**
   *  Handle booking details
   */
  public onDetailsBooking() {
    this.showDetailsDialog = true;
  }

  public closeDetailsBooking() {
    this.showDetailsDialog = false;
    this.$forceUpdate();
  }

  /**
   *  Handle slot moving
   */
  public onSlotMoving() {
    this.showMoveSlotDialog = true;
  }

  public closeSlotMovingDialog() {
    this.showMoveSlotDialog = false;
    this.$forceUpdate();
  }

  /**
   * Reset ship arrival if a double click happened
   */
  public onDoubleClick() {
    this.setCalendarShipArrival(undefined);
  }

  /**
   * Support function to update query parameter
   */
  private updateQueryParameter() {
    // Update query parameter
    this.$router.replace({
      name: this.$route.name!,
      query: {kw: `${this.currentDateTime.year}-${this.currentDateTime.weekNumber}`}
    });
    this.setSelectedWeek(`${this.currentDateTime.year}-${this.currentDateTime.weekNumber}`);
  }

  /**
   * Loads all schedules
   * @private
   */
  private async loadSlotGroups() {
    try {
      this.isLoading = true;
      const DAYS_PER_WEEK = 7;
      const weekStart = this.currentDateTime.startOf('week');
      const payload: { shift: ShiftType, date: string }[] = [];
      for (let i = 0; i < DAYS_PER_WEEK; i++) {
        Object.keys(ShiftType)
          .forEach(shiftKey => {
            payload.push({
              shift: shiftKey as ShiftType,
              date: weekStart.plus({days: i}).startOf('day').toISO()
            });
          });
      }
      await this.getSlotGroupsAction(payload);
    } catch (e) {
      console.log('error', e);
      this.$notifyErrorSimplified('CALENDAR.NOTIFICATIONS.LOADING_ERROR');
    } finally {
      setTimeout(() => {
        this.isLoading = false;

        if (this.$route.query['weekday']) {
          const weekday = (this.$route.query['weekday'] as string);
          let weekStartCopy = DateTime.fromISO(this.currentDateTime.toISO());
          weekStartCopy = weekStartCopy.set({weekday: parseInt(weekday)});
          this.calendarComponent.scrollToDate(weekStartCopy.toUTC().toISO());
        }

      }, 1000);
    }
  }
}

