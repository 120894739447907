import {ShiftType} from '@/enums/ShiftType.enum';
import {ShiftTypeSpecial} from '@/enums/ShiftTypeSpecial.enum';

export const getShiftStartTimes = (weekday: number):
    Array<{ shift: ShiftType, startHour: number, startMinute: number  }> => {
    let shiftObjects: Array<{ shift: ShiftType, startHour: number, startMinute: number  }> = [];

    if (weekday < 6) { // Saturday
        shiftObjects = [{
            shift: ShiftType.FIRST,
            startHour: 6,
            startMinute: 0
        }, {
            shift: ShiftType.SECOND,
            startHour: 14,
            startMinute: 30
        }, {
            shift: ShiftType.THIRD,
            startHour: 22,
            startMinute: 30
        }];
    } else if (weekday === 6) { // Saturday
        shiftObjects = [{
            shift: ShiftType.FIRST,
            startHour: 6,
            startMinute: 0
        }, {
            shift: ShiftType.SECOND,
            startHour: 14,
            startMinute: 30
        }, {
            shift: ShiftType.THIRD,
            startHour: 20,
            startMinute: 30
        }];
    } else if (weekday === 7) { // Sunday
        shiftObjects = [{
            shift: ShiftType.FIRST,
            startHour: 6,
            startMinute: 0
        }, {
            shift: ShiftType.SECOND,
            startHour: 12,
            startMinute: 0
        }, {
            shift: ShiftType.THIRD,
            startHour: 18,
            startMinute: 0
        }];
    }
    return shiftObjects;
}

export const getSpecialShiftStartTimes = (): Array<{ shift: ShiftTypeSpecial, startHour: number, startMinute: number  }> => {
    let shiftObjects: Array<{ shift: ShiftTypeSpecial, startHour: number, startMinute: number  }> = [];

    shiftObjects = [{
        shift: ShiftTypeSpecial.TG,
        startHour: 8,
        startMinute: 0
    }, {
        shift: ShiftTypeSpecial.MS1,
        startHour: 10,
        startMinute: 0
    }, {
        shift: ShiftTypeSpecial.MS2,
        startHour: 18,
        startMinute: 0
    }];

    return shiftObjects;
}
